import React, {
  useContext,
  useMemo,
  useState,
  useEffect
} from "react";
import { Helmet } from "react-helmet";
import GridCard from "./GridCard/GridCard";
import { AppContext } from "../../App";
import { adminLinkConsts } from "../../constants/AdminLink";
import { baseHelpPageUrl } from "../../constants/HelpLink";
import { handlePageviewEvent } from "../../utils/autobahn/autobahnHandler";
import { handleLoginLogoutEvents } from "../../utils/autobahn/autobahnHandler";

import "./dashboard.scss";

const Dashboard = () => {
  const appContext = useContext(AppContext);
  const [spaName, setSpaName] = useState("");
  const [spaRoutes, setSpaRoutes] = useState([]);

  const { navItems, entities, setSelectedSpaId, selectedSpaId, setSelectedpath } = appContext;
  const currentUrl = window.location.href;

  /**
   * pageview event upon page refresh
   * prevent call upon logout pop up open
   */
  useEffect(() => {
    if (localStorage.getItem("dashboardLoaded") && !localStorage.getItem("openLogoutPopup")) {
      handlePageviewEvent("dashboard");
    }
    const routes = JSON.parse(localStorage.getItem("mobileRoutes"));
    setSpaRoutes(routes);
    if(currentUrl.includes('/dashboard')){
      setSelectedpath("");
      setSelectedSpaId('dashboard');
    }
  },[]);

  /**pageview event code */
  useEffect(() => {
    const iesEvent = window.dataLayer?.length && window.dataLayer?.some(data => data?.person_id_type?.toLowerCase() === "ies");
    const loginEvent = localStorage.getItem("autobahnLogin");
    if (iesEvent && !localStorage.getItem("dashboardLoaded")) {
      handlePageviewEvent("dashboard");
      localStorage.setItem("dashboardLoaded", true);
      if (!loginEvent) {
        handleLoginLogoutEvents("login");
        localStorage.setItem('autobahnLogin', true);
      }
    }
  },[selectedSpaId, window.dataLayer]);
  
  /**event code ends */

  /**
   * create admin spa link
   */
  const adminLinkClickHandler = (e) => {
    e?.stopPropagation();
    let {
      prodHostname,
      stgGatewayHostname,
      stgGatewaySpaId,
      prodGatewayHostname,
      prodGatewaySpaId,
      qaGatewaySpaId,
      qaGatewayHostname,
      perfGatewaySpaId,
      perfGatewayHostname,
      stgHostname,
      qaHostname
    } = adminLinkConsts;
    let gatewayhostname, gatewaySpaId;
    if (window.location.hostname === prodHostname) {
      gatewayhostname = prodGatewayHostname;
      gatewaySpaId = prodGatewaySpaId;
    } else if (window.location.hostname === stgHostname) {
      gatewayhostname = stgGatewayHostname;
      gatewaySpaId = stgGatewaySpaId;
    } else if (window.location.hostname === qaHostname){
      gatewayhostname = qaGatewayHostname;
      gatewaySpaId = qaGatewaySpaId;
    } else {
      gatewayhostname = perfGatewayHostname;
      gatewaySpaId = perfGatewaySpaId;
    }
    const adminUrl = `https://${gatewayhostname}/#/application/${gatewaySpaId}/user-list`;
    const adminTab = window.open(adminUrl);
    adminTab.history.pushState(
      null,
      "Pearson Global Gateway-Admin-User accounts",
      adminUrl
    );
  };

  /**
   * Create nav data from 
   * - admin and help link statically
   * - Other from spa configuration API
   */
  const navData = useMemo(() => {
    const staticNavItems = [
      {
        name: "Admin",
        description: "admin",
        link: adminLinkClickHandler,
      },
      {
        name: "HelpHub",
        description: "helphub",
        link: "",
        externalLink: baseHelpPageUrl,
      },
    ];

    return [...navItems, ...staticNavItems];
  }, [navItems]);

  /**
   * On click of any card on the dashboard will
   * open new spa
   */
  const changeApplication = (spaId, spaName, routePath) => {
    if (spaName?.toLowerCase() === "insights") {
      setSelectedSpaId("");
      setSpaName("");
      setTimeout(() => {
        const decodedSpaName = entities.decode(spaName);
        setSelectedSpaId(spaId);
        setSpaName(decodedSpaName);
      }, 0);
    } else {
      const decodedSpaName = entities.decode(spaName);
      setSelectedSpaId(spaId);
      setSpaName(decodedSpaName);
    }
    setSelectedpath(routePath);
  };

  return (
   <>
    <Helmet>
        <title>ActiveHub | Home</title>
    </Helmet>
    <div className="active-hub__dashboard">
      <div className="content-area">
        <h1 className="title">Home</h1>
          <div className="content-area__grid">
            {navData.length &&
              navData.map((nav) => {
                return nav.description === "lesson-plan" ? (
                  spaRoutes?.[nav.description]?.length && spaRoutes[nav.description].map((route) =>
                    <GridCard nav={nav} changeApplication={changeApplication} route={route} />
                  ))
                  :
                  <GridCard nav={nav} changeApplication={changeApplication} route={{}} />
              })}
          </div>
      </div>
    </div>
	</>
  );
};

export default Dashboard;
