export const routerBasePath = "";
export const PMRs = ["urn:pearson:gps:productmodelregion:9b92df41-f614-408a-8e49-d8a255a7b52a", "urn:pearson:gps:productmodelregion:e0fe76ad-bc5a-4dc1-8c7c-caf636e7e2a3"]

export const footerLinks = [
    {
        description: "",
        footerlinkId: "1",
        launchType: "",
        launchUrl: `https://loginstatic.pearson.com/html/PearsonEULA.html?`,
        location: "",
        name: "Terms of use"
    },
    {
        description: "",
        footerlinkId: "2",
        launchType: "",
        launchUrl: `https://support.pearson.com/uk/s/digital-contactus`,
        location: "",
        name: "Contact us"
    }, {
        description: "",
        footerlinkId: "3",
        launchType: "",
        launchUrl: `https://www.pearson.com/en-gb/legal-information/cookie-policy.html`,
        location: "",
        name: "Cookie policy"
    }, {
        description: "",
        footerlinkId: "4",
        launchType: "",
        launchUrl: `https://www.pearson.com/en-gb/privacy-center/privacy-notices.html`,
        location: "",
        name: "Privacy notice"
    }, {
        description: "",
        footerlinkId: "5",
        launchType: "",
        launchUrl: `https://www.pearson.com/en-gb/legal-information/accessibility.html`,
        location: "",
        name: "Accessibility"
    }, {
        description: "",
        footerlinkId: "7",
        launchType: "",
        launchUrl: `https://www.pearsonschoolsandfecolleges.co.uk/help/activehub-terms-conditions`,
        location: "",
        name: "Terms & conditions"
    },
];

export const MAT_PLATFORM_NAME = "ActiveHub Trust";

//Note :  Any new first level child route must be added under mobileroutes too

export const mobileRoutes = [
    {
        id: 1,
        label: 'Resources',
        path: '/courseInstance',
        showChildMenu: true
    },
    {
        id: 2,
        label: 'Tasks',
        path: '/tasks',
        showChildMenu: true
    }
]