import React, { useContext, useMemo, useState, useCallback, useEffect } from 'react';
import './SpaWrapper.scss';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { AppContext } from '../../App';
import MicroFrontend from '../../components/micro-frontend/microfrontend';
import ExploreLessonsSpaLoader from '../../components/explore-lessons/ExploreLessonsSpaLoader';
import { ErrorPopUp, Loader, NavigationHeader, Can, AbilityContext } from 'common-components-spa';
import { useMountEffect, useUpdateEffect } from '../../utils/CustomHooks';
import { Routes } from '../../components';
import UserWrapper from '../UserWrapper/UserWrapper';
import StatusWrapper from '../StatusWrapper/StatusWrapper';
import Notification from '../notification/Notification';
import Dashboard from '../dashboard/Dashboard';

export function SpaWrapper() {
    const { params: { applicationId: encodedApplicationId }, path: matchPath, url: matchURL } = useRouteMatch();
    const currentUrl = window.location.href;
    const history = useHistory();
    const [showMsg, setShowMsg] = useState(false);
    const ability = useContext(AbilityContext);
    const [errorState, setErrorState] = useState({ heading: '', info: '' });
    const {
        spaDetailsList: spaConfig, defaultSpaId: landingPageId,
        selectedSpaId, setSelectedSpaId, iesSession,
        iframeLevelErrorPage, setUrlApplicationId, userId, selectedpath
    } = useContext(AppContext);
    const [spaState, setSPAState] = useState({
        containerId: '',
        name: '',
        host: ''
    });
    const [mfState, setMfState] = useState(false);
    const applicationId = decodeURIComponent(encodedApplicationId);

    const updateSpaState = (id) => {
        const spa = spaConfig.find(spa => spa.spaId === id);
        setSPAState({
            containerId: spa?.spaId,
            name: spa?.name,
            host: spa?.launchPath
        });
        setMfState(true);
    };

    const location = useLocation()

    /**
     * This case handles when there are no SPAs present in the region.
     * Default to Landing Page
     */
    // useMountEffect(() => {
    //     const foundSpa = applicationId && spaConfig.find(spa => spa.spaId === applicationId);
    //     if (!foundSpa && spaConfig.length > 0 && applicationId !== "yetToBeCreated") {
    //         const landingPageExists = landingPageId && spaConfig.find(spa => spa.spaId === landingPageId);
    //         const defaultSpaId = landingPageExists ? landingPageId : spaConfig[0].spaId;
    //         const newEncodedApplicationId = encodeURIComponent(defaultSpaId);
    //         setSelectedSpaId(defaultSpaId);
    //         // setIframeLevelErrorPage(null);
    //         const newUrl = matchPath.replace(":applicationId?", newEncodedApplicationId);
    //         updateSpaState(defaultSpaId);
    //         history.push(newUrl);
    //     } else {
    //         /**
    //          * If the applicationId from URL is not same as selected SPA id,
    //          * set the selected SPA id same as application id.
    //          * This is used to handle reload state, when selectedSpaId is reset to default.
    //          */
    //         setSelectedSpaId(applicationId);
    //         updateSpaState(applicationId);
    //     }
    // });

    useEffect(() => {
        const foundSpa = applicationId && spaConfig.find(spa => spa.spaId === applicationId);
        if (!foundSpa && spaConfig.length > 0 && applicationId !== "yetToBeCreated" && applicationId !== "userProfile" && applicationId !== "notificationPage" && applicationId !== "dashboard" && applicationId !=='courseStatus') {
            if (selectedSpaId =="userProfile" ) {
                history.push("application/userProfile");
            } else if (selectedSpaId =="dashboard") {
                history.push("application/dashboard");
            } else if (selectedSpaId =="courseStatus") {
                history.push("application/courseStatus");
            } else {
                const landingPageExists = landingPageId && spaConfig.find(spa => spa.spaId === landingPageId);
                const defaultSpaId = landingPageExists ? landingPageId : spaConfig[0].spaId;
                const newEncodedApplicationId = encodeURIComponent(defaultSpaId);
                setSelectedSpaId(defaultSpaId);
                sessionStorage.setItem('spaId', defaultSpaId);
                // setIframeLevelErrorPage(null);
                const newUrl = matchPath.replace(":applicationId?", newEncodedApplicationId);
                updateSpaState(defaultSpaId);
                history.push(newUrl);
            }
        } else {
            /**
             * If the applicationId from URL is not same as selected SPA id,
             * set the selected SPA id same as application id.
             * This is used to handle reload state, when selectedSpaId is reset to default.
             */
            setSelectedSpaId(applicationId);
            updateSpaState(applicationId);
            setUrlApplicationId(applicationId)
            sessionStorage.setItem('spaId', applicationId);
        }
    }, [spaConfig])

    useUpdateEffect(() => {
        if (applicationId != selectedSpaId && (selectedpath === "")) {
            console.log(`Application ID changed from ${applicationId} to ${selectedSpaId}`);
            setMfState(false);
            const newEncodedApplicationId = encodeURIComponent(selectedSpaId);
            const newUrl = matchPath.replace(':applicationId?', newEncodedApplicationId);
            history.push(newUrl);
            updateSpaState(selectedSpaId);
        } else if (!currentUrl.includes(selectedpath)) {
            setMfState(false);
            const path = selectedpath === "/tasks" ? '/tasks/list' : selectedpath
            const newEncodedApplicationId = encodeURIComponent(selectedSpaId);
            const newUrl = matchPath.replace(':applicationId?', newEncodedApplicationId) + path;

            history.push(newUrl);
            updateSpaState(selectedSpaId);
        }
    }, [selectedSpaId, selectedpath]);

    function changeApplication(spaId) {
        setSelectedSpaId(spaId);
    }

    function closeErrorPopup() {
        setShowMsg(false)
    }

    const headerTabs = useMemo(() => {
        console.log('spaConfig', spaConfig)
        if((spaConfig[0]?.description == 'student-home') && (window.location.href.search('digitalLibrary') > 0) ){
            console.log("application spa is",spaConfig[0]?.description)
            console.log("application unauthorised for digital content")
        const permissionErrObj = {
            heading: 'Digital Content Access Denied!',
            info: 'You do not have a valid subscription to access this content.',
            subInfo: 'Please contact Support/Administrator.',
            btnName: 'Okay, Got it',
            buttonAction: closeErrorPopup,
            cancelBtnName: '',
            cancelButtonAction: () => {
            },
            cancelBtnStyle: '',
            btnStyle: true,
            image: `${process.env.PUBLIC_URL}/images/error-sorry.svg`,
            onClosePopup: closeErrorPopup
        };

        setErrorState(permissionErrObj);
        setShowMsg(true)
        }

        return spaConfig.filter(item => item.spaId !== landingPageId).map(it => ({
            title: it.name,
            id: it.spaId
        }));
    }, [spaConfig]);

    const errorInterceptor = useCallback((error) => {
        if (error.response.status === 401) {
            iesSession.logout();
        }
        if (error.response.status >= 500 && !(error.response.config.baseURL.includes('learnositysigning'))) {
            // setSelectedSpaId(serviceError)
            // history.replace(`/service-error?error=true&source=${encodeURIComponent(window.location.href)}`);
            // TODO: Show an API failure dialog instead of redirecting to service error page
            console.error('INTERNAL SERVER ERROR', error);
            if (error.response.status >= 500) {
                const permissionErrObj = {
                    heading: `${error.response.status} - ${error.response.statusText}`,
                    info: error.response.data.message,
                    subInfo: '',
                    btnName: 'Try now',
                    buttonAction: closeErrorPopup,
                    cancelBtnName: 'Try Later',
                    cancelButtonAction: () => setShowMsg(false),
                    cancelBtnStyle: false,
                    btnStyle: 'modalbtn',
                    image: `${process.env.PUBLIC_URL}/images/api-failure.svg`,
                    onClosePopup: closeErrorPopup
                };

                setErrorState(permissionErrObj);

                // setErrorState({
                //   heading: `${error.response.status} - ${error.response.statusText}`,
                //   info: error.response.data.message
                // })
                setShowMsg(true)
            }
        }
        if (error.response.status === 403) {
            const permissionErrObj = {
                heading: 'Sorry!!',
                info: 'You are not entitled for this action.',
                subInfo: 'Please contact Support/Administrator.',
                btnName: 'Okay, Got it',
                buttonAction: closeErrorPopup,
                cancelBtnName: '',
                cancelButtonAction: () => {
                },
                cancelBtnStyle: '',
                btnStyle: true,
                image: `${process.env.PUBLIC_URL}/images/error-sorry.svg`,
                onClosePopup: closeErrorPopup
            };

            setErrorState(permissionErrObj);

            // setErrorState({
            //   heading: `${error.response.status} - ${error.response.statusText}`,
            //   info: error.response.data.message
            // })
            setShowMsg(true)
        }
    }, [setShowMsg, setErrorState, iesSession]);

    window.GATEWAY = Object.assign(window.GATEWAY || {}, {
        containerId: applicationId,
        baseName: matchURL,
        errorInterceptor: errorInterceptor
    });
    const microFrontend = useCallback(() => {
        if (spaState.containerId && spaState.host && mfState) {
            return iframeLevelErrorPage ||
                <MicroFrontend {...spaState}
                    errorInterceptor={errorInterceptor}
                // setIframeLevelErrorPage={setIframeLevelErrorPage} 
                />
        } else {
            return <ExploreLessonsSpaLoader />
        }
    }, [selectedSpaId, spaState, mfState, iframeLevelErrorPage, errorInterceptor])

    const routes = useMemo(() => [
        {
            component: () => <div>In progress</div>,
            path: "/application/yetToBeCreated",
            exact: false,
            title: "Global Gateway Wrapper",
        },
        {
            component: Dashboard,
            path: "/application/dashboard",
            exact: false,
            title: "Global Gateway Wrapper",
        },
        {
            component: UserWrapper,
            path: "/application/userProfile",
            exact: false,
            title: "Global Gateway Wrapper",
        },
        {
            component: StatusWrapper,
            path: "/application/courseStatus",
            exact: false,
            title: "Global Gateway Wrapper",
        },
        {
            component: Notification,
            path: "/application/notificationPage",
            exact: false,
            title: "Global Gateway Wrapper",
        },
        {
            component: () => (microFrontend()),
            path: "/application/:applicationId?",
            exact: false,
            title: "Global Gateway Wrapper",
        },

    ], [microFrontend])

    useEffect(() => {
        window.Appcues && window.Appcues.page();
    }, [location])

    useEffect(() => {
        let userID = Math.random()
        console.log("userID appcues", userID)
        console.count(userID)
        let userRole = "Admin";
        userRole = ability.can("VIEW", "LESSON_PLAN") ? "Instructor": ability.can("VIEW", "STUDENT_HOME") ? "Learner": "Admin";
        window.Appcues && window.Appcues.identify(userId,
            {
                createdAt: Date.now(), // Unix timestamp of user signup date
                role: userRole, // Current user’s role or permissions
            }
        );
    }, [])

    const defaultRoute = routes[0].path;
    return (
        <div className="gwSpaWrapper">
            <ErrorPopUp
                isOpen={showMsg}
                heading={errorState.heading}
                info={errorState.info}
                btnName={errorState.btnName}
                buttonAction={errorState.buttonAction}
                btnStyle={errorState.btnStyle}
                cancelBtnName={errorState.cancelBtnName}
                cancelButtonAction={errorState.cancelButtonAction}
                cancelBtnStyle={errorState.cancelBtnStyle}
                image={errorState.image}
                closePopup={errorState.onClosePopup}
                subInfo={errorState.subInfo}
            />
            {/* {​​ability.can("READ", "GATEWAY_CONFIGURATION_SPA") && console.log('true')}​​ */}
            {/* <Can I="READ" a="GATEWAY_CONFIGURATION_SPA">
            <div className="gwSpaWrapper__tabs">
            
            <NavigationHeader
                    tabs={headerTabs}
                    onSelect={changeApplication}
                    activeTabId={spaState.containerId}
                    needCarousal={true}
                />
            
            </div>
            </Can> */}
            <div className="gwSpaWrapper__iframe">
                <Routes routes={routes} defaultRoute={defaultRoute} />
            </div>
        </div>
    );
}
