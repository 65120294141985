import React, { useContext, useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import "./Header.scss";
import { AppContext } from '../../App';
import { Button, Can, AppDrawer } from 'common-components-spa'
import { Nav } from 'react-bootstrap';
import NotificationPopup from "../notification-popup/NotificationPopup";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { platFormIds, adminLinkConsts, isActivehub } from "../../constants/AdminLink";
import {
  TEACHER_HELP_PAGE_BASE_URL,
  STUDENT_HELP_PAGE_BASE_URL,
  INSIGHTS_STUDENT_SHARE_KEY,
  STUDENT_HOME_HELP_SECTION_MAP,
  INSIGHTS_HELP_SECTION_MAP,
  LESSON_PLAN_HELP_SECTION_MAP,
  OLD_HELP_PAGE_BASE_URL,
  ASSESSMENTS_SPA_ID,
  INSIGHTS_SPA_ID,
  DASHBOARD_ID
} from "../../constants/HeaderInputs";
import useMediaQuery from '@material-ui/core/useMediaQuery';

/*
  This is temporary fix 
  Detect URL change and close left menu items for mobile device
*/

const locationHashChanged = () => {
  const sideBarContainer = document.getElementById("sidebar-container");
  if (!sideBarContainer) return;
  sideBarContainer.classList.remove("side-nav__exp");
}
window.onhashchange = locationHashChanged;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    padding: "0px",
    color: "rgb(0, 127, 163)",
    marginRight: theme.spacing(2)
  },
}));

export function Header(props) {
  const history = useHistory();
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const { pathname } = useLocation();
  const [headerImageLoaded, setHeaderImageLoaded] = useState(false);
  const [pageReferenceArr, setPageReferenceArr] = useState([]);
  const [spaName, setSpaName] = useState("")
  const { selectedSpaId, setSelectedSpaId, entities, notifications, isOnlyStudentHomeSpa, appdrawerList, setSelectedpath, selectedpath } = appContext;
  const { assessmentPage } = props
  const isMobile = !useMediaQuery('(min-width:768px)');
  const currentUrl = window.location.href;

  const {
    prodHostname,
    stgHostname,
    qaHostname
  } = adminLinkConsts;
  const { devPlatFormId, qaPlatFormId, stgPlatFormId, prodPlatFormId } = platFormIds
  let platformId = ''
  if (window.location.hostname === prodHostname) {
    platformId = prodPlatFormId
  } else if (window.location.hostname === stgHostname) {
    platformId = stgPlatFormId
  } else if (window.location.hostname === qaHostname) {
    platformId = qaPlatFormId
  } else {
    platformId = stgPlatFormId
  }

  useEffect(() => {
    const pageReferenceArr = window.location.href.split("/").filter(e => /^[a-zA-Z]+\-?[a-zA-Z]*$/.test(e))
    setPageReferenceArr(pageReferenceArr)
  }, [window.location.href])

  const appClickHandler = (path) => {
    const appDraw = window.open(path);
    appDraw.history.pushState(
      null,
      "Pearson Global Gateway",
      appDraw
    );

  }

  /**
   * get different base url
   * for diffenet spas
   */
  const baseHelpPageUrl = useMemo(() => {
    if (appContext.isOnlyStudentHomeSpa) {
      return STUDENT_HELP_PAGE_BASE_URL;
    }
    else {
      if (ASSESSMENTS_SPA_ID.includes(selectedSpaId) ||
        INSIGHTS_SPA_ID.includes(selectedSpaId) ||
        selectedSpaId === DASHBOARD_ID) {
        return OLD_HELP_PAGE_BASE_URL;
      } else {
        return TEACHER_HELP_PAGE_BASE_URL;
      }
    }
  }, [pageReferenceArr, selectedSpaId]);

  /**get key for insights pages */
  const getInsightsUrlKey = (pageReferenceArr) => {
    const insightsKeys = Object.keys(INSIGHTS_HELP_SECTION_MAP);
    let insightsUrlKey = pageReferenceArr.find(key => insightsKeys.includes(key?.toLowerCase()));
    if (insightsUrlKey === "assessments") {
      if (pageReferenceArr.includes(INSIGHTS_STUDENT_SHARE_KEY)) {
        insightsUrlKey = INSIGHTS_STUDENT_SHARE_KEY?.toLowerCase();
      }
    }
    return insightsUrlKey;
  }

  /**return help hub section for all spas */
  const returnHelpSection = useMemo(() => {
    const urlKey = pageReferenceArr[pageReferenceArr.length - 1]?.toLowerCase();
    console.log("page ref array: ", pageReferenceArr); //remove after testing on QA
    console.log("selected spa id: ", selectedSpaId); // remove after moving to RFV
    sessionStorage.setItem('spaId', selectedSpaId);
    if (appContext.isOnlyStudentHomeSpa) return STUDENT_HOME_HELP_SECTION_MAP[urlKey];
    else if (ASSESSMENTS_SPA_ID.includes(selectedSpaId) && assessmentPage) return assessmentPage;
    else if (INSIGHTS_SPA_ID.includes(selectedSpaId)) {
      const insightsUrlKey = getInsightsUrlKey(pageReferenceArr);
      return INSIGHTS_HELP_SECTION_MAP[insightsUrlKey];
    }
    return LESSON_PLAN_HELP_SECTION_MAP[urlKey];
  }, [assessmentPage, selectedSpaId, pageReferenceArr])

  function handleHeaderImageLoad() {
    if (!headerImageLoaded) {
      setHeaderImageLoaded(true);
    }
  }

  function handleBtnKeyDown(event) {
    if (event.key !== "Tab") {
      event.preventDefault();
    }
    if (event.key === " " || event.key === "Enter" || event.key === "Spacebar") {
      props.handleHeaderClick();
    }
  }

  function keyPressed(event) {
    event.preventDefault();
    if (event.key === "Enter") {
      props.showDropDown();
    }
  }

  const changeApplication = (spaId, spaName, path = '') => {
    setSelectedpath(path)
    if (spaName?.toLowerCase() === "insights") {
      setSelectedSpaId("")
      setSpaName("")
      setTimeout(() => {
        const decodedSpaName = entities.decode(spaName)
        setSelectedSpaId(spaId);
        setSpaName(decodedSpaName)
      }, 0)
    } else {
      const decodedSpaName = entities.decode(spaName)
      setSelectedSpaId(spaId);
      setSpaName(decodedSpaName)
    }
  }

  const handleKeyboardDrawerToggle = (e) => {
    if (e.key === "Enter") {
      props.handleDrawerToggle()
    }
  }

  const handleLogoClick = () => {
    if (!isOnlyStudentHomeSpa) {
      setSelectedSpaId('dashboard');
      history.push('/application/dashboard');
    } else {
      if (selectedSpaId) {
        props.handleHeaderClick();
      }
    }
  };

  const handleSupportClick = (evt) => {
    evt.preventDefault();
    if(selectedSpaId) {
      window.open(`${baseHelpPageUrl}${returnHelpSection ? "#" : ""}${returnHelpSection || ""}`, "_blank");
    }

  }

  return (
    <header>
      <div className={`gwHeader`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="gwHeaderLogo">
                <div onKeyPress={headerImageLoaded || props.isMobile ? () => { } : handleBtnKeyDown} tabIndex={headerImageLoaded || props.isMobile ? "" : "0"}>
                  <img
                    src={props.headerImage}
                    title={props.headerDetails.description}
                    alt='Pearson ActiveHub'
                    onClick={handleLogoClick}
                    className={`gwHeaderLogo__img gwHeaderLogo__${(headerImageLoaded) ? 'hide' : 'show mbnone'}`}
                  />
                  <img
                    src={props.headerDetails.headerImageUrl}
                    title={props.headerDetails.description}
                    alt='Pearson ActiveHub'
                    onLoad={handleHeaderImageLoad}
                    onClick={selectedSpaId ? props.handleHeaderClick : () => { }}
                    className={`gwHeaderLogo__img gwHeaderLogo__${(headerImageLoaded) ? 'show' : 'hide'}`}
                  />
                  {props.isMobile && !(pathname.includes('/digitalLibrary/list/') || pathname.includes('/alfresco/player/')) &&
                    <IconButton
                      aria-label="open menu"
                      onClick={props.handleDrawerToggle}
                      className={classes.menuButton}
                      onKeyDown={handleKeyboardDrawerToggle}
                    >
                      <MenuIcon />
                    </IconButton>}
                  {!props.isMobile &&
                    <img src={`${process.env.PUBLIC_URL}/images/Pearson-mobilelogo.svg`} className="d-xl-none" tabIndex="" alt="pearson global gateway" />
                  }
                </div>
              </div>
            </div>
            {!props.isMobile && <div className={`${pathname.includes('/digitalLibrary/list/') || pathname.includes('/alfresco/player/') ? 'd-none' : 'd-flex'}  align-items-center`}>
              <div className="gwSpaWrapper__tabs--ahub">
                {!props.isMobile &&
                  <Can I="READ" a="GATEWAY_CONFIGURATION_SPA">
                    <Nav className={`d-flex gwTab ${props.styleClass || ' '} `}>
                      {!isOnlyStudentHomeSpa &&
                        <>
                          <Nav.Item className="gwTabitems" key={'dashboard'}>
                            <Nav.Link
                              aria-current="page"
                              active={selectedSpaId === 'dashboard'}
                              role="tab"
                              className={`gwTabitems__link ${selectedSpaId === 'dashboard' && 'active'}`}
                              onClick={() => { setSelectedSpaId('dashboard'); setSelectedpath('') }}
                              eventKey={'dashboard'}
                            >
                              Home
                            </Nav.Link>
                          </Nav.Item>
                          {props.navItems.map((tabTitle) =>
                            tabTitle.description === "lesson-plan" ?
                              <>
                                <Nav.Item className="gwTabitems" key={tabTitle.key}>
                                  <Nav.Link aria-current="page" id='resources' active={currentUrl.includes("/courseInstance")} role="tab" className={`gwTabitems__link ${currentUrl.includes("/courseInstance") && 'active'}`}
                                    onClick={() => changeApplication(tabTitle.spaId, tabTitle.name, "/courseInstance")}
                                    eventKey={tabTitle.spaId}>
                                    {'Resources'}
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="gwTabitems" key={tabTitle.key}>
                                  <Nav.Link aria-current="page" active={currentUrl.includes("/tasks")} role="tab" className={`gwTabitems__link ${currentUrl.includes("/tasks") && 'active'}`}
                                    onClick={() => changeApplication(tabTitle.spaId, tabTitle.name, "/tasks")}
                                    eventKey={tabTitle.spaId}>
                                    {'Tasks'}
                                  </Nav.Link>
                                </Nav.Item> </> :
                              <Nav.Item className="gwTabitems" key={tabTitle.key}>
                                <Nav.Link aria-current="page" active={selectedSpaId === tabTitle.spaId} role="tab" className={`gwTabitems__link ${selectedSpaId === tabTitle.spaId && 'active'}`}
                                  onClick={() => changeApplication(tabTitle.spaId, tabTitle.name)}
                                  eventKey={tabTitle.spaId}>
                                  {entities.decode(tabTitle.name)}
                                  {/* {tabTitle.name} */}
                                </Nav.Link>
                              </Nav.Item>

                          )}
                        </>
                      }
                      {!isOnlyStudentHomeSpa && props.navItems.length > 0 &&
                        <Nav.Item className="gwTabitems" key="adminLink">
                          <Nav.Link aria-current="page" role="tab" className={`gwTabitems__link`}
                            onClick={(e) => props.adminLinkClickHandler(e)}>
                            Admin
                          </Nav.Link>
                        </Nav.Item>
                      }
                    </Nav>
                  </Can>
                }
              </div>
            </div>
            }
            {props.isMobile &&
              <div className={`${pathname.includes('/digitalLibrary/list/') || pathname.includes('/alfresco/player/') ? 'mobile-logo-av' : 'mobile-logo'}`} onClick={handleLogoClick}>
                <img src={`${process.env.PUBLIC_URL}/images/Pearson-mobilelogo.svg`} className="d-xl-none" tabIndex="" alt="pearson global gateway" />
              </div>
            }
            <div className={`d-flex align-items-center ${!props.isMobile ? 'header-right-section': ''}`}>
              {/* <Can not I="VIEW" a="STUDENT_HOME"> */}
              {appContext.isOnlyStudentHomeSpa && (
                <div className={`${pathname.includes('/digitalLibrary/list/') || pathname.includes('/alfresco/player/') ? 'd-none' : 'gwnotifi'}`}>
                  <Button className="button__v2 p-0"
                    onClick={selectedSpaId ? props.openNotificationPopup : () => { }}
                    customDataAttributes={{
                      "aria-haspopup": "true",
                      "aria-controls": "notificationMenu",
                      "id": "notificationMenuButton",
                      "aria-expanded": props.isNotificationPopupOpen ? true : false
                    }}
                  >
                    <img src={`${process.env.PUBLIC_URL}/images/${notifications.length ?
                      "notification-highlight.svg" : "notification.svg"}`} alt="Notification menu" />
                  </Button>
             
                <NotificationPopup
                  isOpen={props.isNotificationPopupOpen}
                  onClose={props.closeNotificationPopup}
                  customDataAttributes={{
                    "id": "notificationMenu",
                    "aria-labelledby": "notificationMenuButton"
                  }}
                />
              </div>
              )}           
              {/* </Can>  */}
              {/* <div className="gwHelp">  
                <a href={selectedSpaId ? `${baseHelpPageUrl}${returnHelpSection ? "#" : ""}${returnHelpSection || ""}` : void (0)} title="Pearson Help" className="material-icons gwhelp-icon" rel="noopener noreferrer" target="_blank" onClick={handleSupportClick}>help</a>
              </div> */}
              <div className="gwhelp">
                {console.log("appContext.isOnlyStudentHomeSpa", appContext.isOnlyStudentHomeSpa)}
                {props.isMobile ? 
                <a href={selectedSpaId ? `${baseHelpPageUrl}${returnHelpSection ? "#" : ""}${returnHelpSection || ""}` : void (0)} className="material-icons gwhelp-icon"
                  rel="noopener noreferrer" target="_blank">help_outline
                  <span class="screen-reader-only">Opens in a new window</span></a> 
              : 
              <Button  className="helpHub button__v2 button__v2__primary__bordered d-flex align-items-center mr-3" 
              onClick={handleSupportClick}
              ><span className="help-text"><img src={`${process.env.PUBLIC_URL}/images/help-hub.svg`} className="helpIcon mr-0" alt="HelpHub" /> HelpHub</span><span class="screen-reader-only">Opens in a new window</span></Button>
              }
                </div>
              <div className="gwProfile">
                <Button className={`gwAvatar__btn m-0 p-0`}
                  aria-controls="user_menu"
                  value={props.userInitials}
                  onClick={props.showDropDown} onKeyPress={keyPressed}
                  customDataAttributes={{
                    "aria-label": `${props.userInitials} account options`,
                    "aria-expanded": props.isOpen ? true : false
                  }}
                />
                {props.children}
              </div>
              <div className="appdrawer">
                <AppDrawer appDrawerList={appdrawerList} isActivehub={isActivehub} appClickHandler={appClickHandler} closeIcon={'close'} type={platformId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
Header.defaultProps = {
  headerDetails: {
    name: "Pearson",
    description: "pearson global gateway",
    welcome: "Welcome!"
  },
  headerImage: `${process.env.PUBLIC_URL}/images/Pearson-everest-logo.png`
}

Header.propTypes = {
  showDropDown: PropTypes.func.isRequired,
  userInitials: PropTypes.string,
  headerImage: PropTypes.string,
  headerDetails: PropTypes.object,
  handleHeaderClick: PropTypes.func,
  showWelcomeMsg: PropTypes.bool
}
