import React from "react";

import "./gridcard.scss";

const GridCard = ({ nav, changeApplication, route = {} }) => {
  const tagLine = {
    admin: "Manage users and groups.",
    assessments: "Assessment library, assessment builder and mark entry.",
    helphub: "Tips and tutorials on using ActiveHub.",
    insights: "Performance analysis for groups, students, assessments and tasks.",
    "lesson-plan": {
      "resources": "A library of resources to support teaching, practice and intervention.",
      "tasks": "Review tasks for your learners and track progress.",
    }
  };

  /**
   * handle click handler of spa
   */
  const onClickHandler = () => {
    if (nav.link instanceof Function) {
      /**
       * if link type is of function that mean we have to
       * generate the admin link and redirect to admin
       */
      nav.link();
    } else if (nav?.externalLink) {
      /**
       * if external link set then redirect to the set URL
       */
      window.open(nav?.externalLink);
    } else {
      changeApplication(nav.spaId, nav.name, Object.keys(route).length === 0 ? "" : route.path);
    }
  };

  return (
    <div className="dashboard-gridcard" onClick={onClickHandler}>
      <div className="divider"></div>
      <img
        src={`${process.env.PUBLIC_URL}/images/${Object.keys(route).length === 0 ? nav.description : route?.label.toLowerCase()}-dashboard-icon.svg`}
        alt={`${Object.keys(route).length === 0 ? nav.description : route?.label.toLowerCase()} icon`}
      />
      <h2 className="title">{Object.keys(route).length === 0 ? nav.name : route.label}</h2>
      <p>{Object.keys(route).length === 0 ? tagLine[nav.description] : tagLine[nav.description][route?.label.toLowerCase()]}</p>
    </div>
  );
};

export default GridCard;
