import { NetworkOps } from 'common-components-spa'

export const userLocale = navigator.language || 'en-US';
let environment = ['dev', 'qa', 'stg', 'perf']
let gethostname = window.location.hostname.split(".")[0];
let nullProxyBaseUrl = environment.includes(gethostname) ? `https://${gethostname}.ggw.ift.pearson-intl.com` : 'https://ggw.ift.pearson-intl.com';
let baseURL = (sessionStorage.getItem('proxyBaseUrl') != null) ? sessionStorage.getItem('proxyBaseUrl') : nullProxyBaseUrl;
const permissionAPI = new NetworkOps({
    baseURL: `/api/activehub-node/permission/v1`
})

const userAbilityAPI = new NetworkOps({
    baseURL: `/api/activehub-node`
})

export const rootAPI = new NetworkOps({
    baseURL: 'https://stg.ggw.ift.pearson-intl.com/api'
});

export const proxyAPI = new NetworkOps({
    baseURL: `/api/activehub-node/proxy/v1`
});

export const proxyAPIV2 = new NetworkOps({
    baseURL: `/api/activehub-node/proxy/v2`
});

export const gatewayConfigurationApi = new NetworkOps({
    baseURL: `/api/activehub-node/configuration/v1`,
});

export const notificationApi = new NetworkOps({
    baseURL: `/api/activehub-node/notification/v1`,
});

/* whenever a new networkOps is defined add it in setAuthToken and setUserIdHeader */
export function setAuthToken(token) {
    rootAPI.setAuthToken(token);
    permissionAPI.setAuthToken(token);
    userAbilityAPI.setAuthToken(token);
    proxyAPI.setAuthToken(token);
    proxyAPIV2.setAuthToken(token);
    gatewayConfigurationApi.setAuthToken(token);
    notificationApi.setAuthToken(token);
}

export function setUserIdHeader(id) {
    rootAPI.setUserId(id);
    permissionAPI.setUserId(id);
    userAbilityAPI.setUserId(id);
    proxyAPI.setUserId(id);
    proxyAPIV2.setUserId(id);
    gatewayConfigurationApi.setUserId(id);
    notificationApi.setUserId(id);
}

export function getUserProfile(userId) {
    return userAbilityAPI.get(`/user/v1/user/${userId}`);
}
/**
 * Update the user profile
 * @param userId 
 * @param data 
 */
export function updateUserProfile(userId, data) {
    return userAbilityAPI
        .patch(`/user/v1/user/${userId}/profile`, data);
}

/**
 * Getting the Role of the user
 * @param data 
 * @param token 
 */
export function getUserRole(data) {
    return userAbilityAPI
        .post('/usersearch/v1/search/users', data);
}

export function getUserAbilities(userId, regionId) {
    return proxyAPI.get(`/getUserAbilityByUserIdRegionId/${userId}/${regionId}`)
}
export function getSPAsInRegion(region, locale = userLocale) {
    return proxyAPI.get(`/getSPAInRegion/${region}/locale/${locale}`)
}

export function getHeaderInfo() {
    return gatewayConfigurationApi.get(
        `/configuration/findByHostname/${window.location.host}/locale/${userLocale}`);
}

export function getNotifications() {
    return notificationApi.get(`/inappnotifications/unread`);
}

export function readNotification(noId) {
    return notificationApi.put(`/inappnotification/${noId}/read`);
}

export function getUserStatus(pmrIds) {
    return proxyAPI.post(`/getUserStatus`, pmrIds);
}

export function getOrgDetailsByUserV2(userId) {
    return proxyAPIV2.get(`/organisationDetailsForUser/user/${userId}/locale/en-GB`);
}

export function getAppcuesConfig() {
    return rootAPI.get(`/getAppcuesConfig`)
}

export function getTierInformation() {
    return proxyAPI.get(`/getTierData`)
}

export function getPreviewLiveStatus(pmrId, orgId, isPPID) {
    return proxyAPI.get(`/previewLiveStatus/pmr/${pmrId}/organization/${orgId}/${isPPID}`)
}

export function makeCourseLive(pmrId, orgId, payload) {
    return proxyAPI.put(`/previewLiveStatus/pmr/${pmrId}/organization/${orgId}`, payload)
}

export function getPearsonAdmin(pmrId, userId) {
    return proxyAPI.get(`/previewLiveStatus/pmr/${pmrId}/userId/${userId}`)
}

export function getUserPlatform() {
    console.log("calling platform endpoint...")
    return proxyAPI.get(`/getUserPlatformAccess`)
}

export function getAppdrawer(userId) {
    return proxyAPI.get(`/getAppdrawerList/userId/${userId}/local/en-US`)
}

export function getAppStatus() {
    return proxyAPI.get(`/getAppStatus`);
}